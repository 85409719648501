import { render, staticRenderFns } from "./PartnerList.vue?vue&type=template&id=2764555f&scoped=true&"
import script from "./PartnerList.vue?vue&type=script&lang=js&"
export * from "./PartnerList.vue?vue&type=script&lang=js&"
import style0 from "./PartnerList.vue?vue&type=style&index=0&id=2764555f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2764555f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConditionalLink: require('/src/components/ConditionalLink/conditionalLink.vue').default})
