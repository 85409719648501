
export default {
  data () {
    return {
      element: ``,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.element = document.getElementById(`sotic-cookie-notice`);
      // Get cookie, if not found, remove all cookies
      if (!this.$cookies.get(`sotic-accept-cookies`)) {
        // Remove all cookies from application if not accepted by user
        // this.$cookies.removeAll();
        this.element.style.display = `block`;
      }
    });
  },
  methods: {
    acceptCookies () {
      this.element.style.display = `none`;
      this.$cookies.set(`sotic-accept-cookies`, true, {
        path: `/`,
        maxAge: 60 * 60 * 24 * 7, // 1 week
      });
    },
  },
};
