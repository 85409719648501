
import jwt_decode from "jwt-decode"; // eslint-disable-line camelcase

export default {
  data () {
    return {
      user: {
        type: [Boolean, Object],
        default: () => false,
      },
      cookieOptions: {
        maxAge: 86400,
        path: `/`,
      },
      userKey: `aberdeen_sotic_sa_user`,
      tokenKey: `aberdeen_sotic_sa_token`,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.soticSAInit();
    });
  },
  methods: {
    handleMemberDropdown (state) {
      this.memberDropdownActivated = state;
    },
    /**
       * @description Returns a user ID after running Caesar shift algorithm
       * @param {texting} cookie - the encoded cookie texting
       * @param {*} shift - the Caesar shift index
      */
    caesarShift (text, shift) {
      // Make an output variable & go through each character
      let output = ``;
      for (let i = 0; i < text.length; i++) {
        // Get the character we'll be appending to output
        let char = text[i];

        // Check it is a letter
        // Get its code
        const code = char.match(/[a-z]/i) ? text.charCodeAt(i) : 0;

        // Uppercase letters
        if (code >= 65 && code <= 90) {
          char = String.fromCharCode(((code - 65 + shift) % 26) + 65);
        } // eslint-disable-line

        // Lowercase letters
        else if (code >= 97 && code <= 122) {
          char = String.fromCharCode(((code - 97 + shift) % 26) + 97);
        }

        // Append
        output += char;
      }

      // All done!
      return output;
    },
    /**
       * @description Decodes the tokenpayload into a JWT token from the URL query string via a caesar shift
       * @param {String} token - the token payload URL containing user information
       * @return {String} a decoded token string
       */
    decodeToken (token) {
      let entropy = token.match(/.{1,22}/g);
      entropy.splice(entropy.indexOf(entropy[1]), 1);
      entropy = entropy.join(``);
      return atob(this.caesarShift(entropy, 15));

      // return `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfbmFtZSI6ImRhbi5kcmF2ZUBzb3RpYy5jby51ayIsIm5hbWVpZCI6ImRhbi5kcmF2ZUBzb3RpYy5jby51ayIsImVtYWlsIjoiZGFuLmRyYXZlQHNvdGljLmNvLnVrIiwic3ViIjoiZGFuLmRyYXZlQHNvdGljLmNvLnVrIiwidXNlcm5hbWUiOiJkYW4uZHJhdmUiLCJ0eXBlIjoidXNlciIsInRpdGxlIjoiTXIiLCJmb3JlbmFtZSI6IkRhbmllbCIsInN1cm5hbWUiOiJEcmF2ZSIsImZvcm1hbF9uYW1lIjoiRCBEcmF2ZSIsImdlbmRlciI6Im1hbGUiLCJhZ2VfcmFuZ2UiOiIyMS0zMCIsImRvYiI6IjA4LzA0LzE5OTIiLCJoYXNfcGhvbmUiOiJ0cnVlIiwicHJvZmlsZV9zdGF0ZSI6ImZ1bGx0bXMiLCJpZCI6IngxUjRoNUtQMTBlNTBFREQ4cUhUOHciLCJsYXN0X21vZGlmaWVkIjoiMTYyMzk0NDcxMSIsImV4dF9hY2NfdGlja2V0aW5nIjoiMjAwNjA3MDgiLCJoYXNfYWRkcmVzcyI6InRydWUiLCJjbHViIjoiYWZjIiwidGVuYW50X2lkIjoiWkhXYnU1bVBLMC10TFVTc254bFIzdyIsIm5iZiI6IjE2NjA1NTQ2MTkiLCJpYXQiOiIxNjYwNTU0NjQ5IiwiaXNzIjoiaHR0cHM6Ly9zc28uc3BvcnRzYWxsaWFuY2UuY29tIiwiYXVkIjoiWjZIaGhXeXJYVUM1a1E0QWZyaFlWdyIsImV4cCI6MTY2MDU2MDA0OX0.zINVz0t3DQZr9gRN1vcED82cFiyyewfdETGFq-irrvY`;
    },
    /**
       * @description Decodes a JSON web token into user information
       * @param {String} jwt_token - decodes a JSON web token into a User object
       * @return {Object} - the User object
       */
    decodeJWTToken (jwtToken) {
      return jwt_decode(jwtToken); // 3rd party dep script (deps/jwt_decode.js)
    },
    /**
       * @description Models and decodes token data into a User object
       * @param {string} token - the raw user token from the Sports Alliance API
       */
    getToken (token) {
      // Get JWT token and set to cookie
      const jwtToken = this.decodeToken(token);

      // Model our decoded token into a usable request object
      const tokenObj = {};
      const data = jwtToken.split(`&`);
      data.forEach(item => {
        const itemArr = item.split(`=`);
        tokenObj[itemArr[0]] = itemArr[1];
      });
      return {
        data: tokenObj,
        decoded_data: this.decodeJWTToken(jwtToken),
      };
    },
    /**
       * @description Stores a user access token in application to use against requests to SportsAlliance API
       * @param {texting} token - the access token from the URL
       */
    storeToken (token) {
      // Store API response in cookies & vue store
      this.user = this.getToken(token);

      // Get JWT Token and User Data
      const jwtToken = this.user?.data?.access_token;
      const userData = this.user?.decoded_data;

      // Store in cookies and vue store (latter in case page fails to refresh)
      this.$cookies.set(this.tokenKey, jwtToken, this.cookieOptions, ``, ``, true);
      this.$store.dispatch(`auth/setToken`, jwtToken);
      this.$cookies.set(this.userKey, JSON.stringify(userData), this.cookieOptions, ``, ``, true);
      this.$store.dispatch(`auth/setUser`, userData);

      // Reload page without payload in URL
      window.location = window.location.pathname;
    },
    /**
     * @description Initialisation logic of SportsAlliance single-sign on integration
     */
    soticSAInit () {
      const urlParams = new URLSearchParams(window.location.search);

      // If logging in, store token in cookies
      if (urlParams.get(`tokenpayload`)) {
        this.storeToken(urlParams.get(`tokenpayload`));
      } else if (this.$cookies.get(this.tokenKey)) {
        // If not logging in, check if we have a token in cookies and set in store
        this.$store.dispatch(`auth/setToken`, this.$cookies.get(this.tokenKey));
        this.$store.dispatch(`auth/setUser`, this.$cookies.get(this.userKey));
        this.getMembership();
      }
    },
    async getMembership () {
      if (this.$store.state.auth?.token) {
        const membership = await this.$getMembership(this.$store.state.auth?.token);
        if (membership) {
          this.$store.dispatch(`auth/setMembership`, membership);
        } else {
          this.$store.dispatch(`auth/setMembership`, null);
        }
      }
    },
  },
};
