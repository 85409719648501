
export default {
  data () {
    return {
      partners: [],
      carouselOptions: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
        modules: `modules`,
        centeredSlides: true,
        loop: false,
        speed: 1500,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        breakpoints: {
          640: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 10,
          },
        },
      },
      carousel: null,
    };
  },
  computed: {
    partnerTiers () {
      return this.siteSettings.partner_tiers;
    },
    partnerIds () {
      // All partners to be returned from the API reduced to an array of IDs
      return this.partnerTiers.reduce((acc, tier) => acc.concat(tier.tier_partners.map(partner => partner.ID)), []);
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.getPartners();
    });
  },
  methods: {
    async getPartners () {
      const queryParams = {
        fields: `id,title,acf,image,slug`,
        posts: {
          post_type: `partner`,
          posts_per_page: 100,
          post_status: `publish`,
          post__in: this.partnerIds,
          orderby: `menu_order`,
          order: `DESC`,
        },
        options: {
          image_size: `medium`,
        },
      };
      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.partners = request;
      }
    },
    getPartner (id) {
      return this.partners.find(partner => partner.id === id);
    },
  },
};
