
export default {
  props: {
    post: {
      type: Object,
      default: () => null,
    },
    content: {
      type: String,
      default: ``,
    },
    lessPadding: {
      type: Boolean,
      default: false,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: ``,
    },
    showDfp: {
      type: Boolean,
      default: false,
    },
    isArticle: {
      type: Boolean,
      default: false,
    },
    isOldArticle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      blockId: `block-${Math.random().toString(36)
        .substr(2, 7)}`,
    };
  },
  mounted () {
    this.makeFluidVideo();
    // this.renderContentScripts();
    this.reduceBlankPs();
    this.checkAdminLinks();

    if (this.showDfp) {
      setTimeout(() => {
        this.renderDfp();
      }, 500);
    } else {
      this.renderContentScripts();
    }

    // Content import shortcode scripts if required - One for documents also available
    // this.generatePhotoShortcodes();
    // this.generateYoutubeShortcodes();
  },
  methods: {
    makeFluidVideo () {
      const videos = document.querySelectorAll(`.content__content p > iframe`);
      if (videos) {
        videos.forEach(video => {
          const parentContainer = video.parentElement;
          parentContainer.classList.add(`content__video`, `content__video--fluid`);
        });
      }
    },
    renderContentScripts () {
      const scripts = document.querySelectorAll(`.content__content script`);
      scripts.forEach(target => {
        // Fine our parent element to append new script too
        const parent = target.parentElement;
        const script = document.createElement(`script`);

        // Build our new script, to append via appendChild which will execute the script
        script.type = target.type;
        script.src = (target.src) ? target.src : ``;
        script.innerHTML = target.innerHTML;

        // Remove non-initliased script and append our new script
        parent.removeChild(target);
        parent.appendChild(script);
      });
    },
    reduceBlankPs () {
      /*
        Reduce the size of <p> elements in imported content
        that just contain spaces.
      */
      const paragraphs = document.querySelectorAll(`.content__content p`);
      paragraphs.forEach(paragraph => {
        const p = paragraph.innerHTML.replace(/&nbsp;/g, ``);
        if (p.length === 0) {
          paragraph.classList.add(`blank`);
        }
      });
    },
    generateYoutubeShortcodes () {
      const content = document.querySelector(`.content__content`);
      if (content) {
        const youtubeShortcodeStrings = content.innerHTML.match(/\[[^[]*YOUTUBE [^\]]*\]/g);
        if (youtubeShortcodeStrings) {
          for (const shortcode of youtubeShortcodeStrings) {
            const youtubeArr = shortcode.split(`ID=`);
            const youtubeId = youtubeArr[1].replace(/[^A-Za-z0-9\s]/g, ``);
            const youtubeUrl = `https://youtube.com/embed/${youtubeId}?rel=0`;

            const youtubeEl = `<div class="content__video content__video--fluid">
            <iframe width="640" height="360" src="${youtubeUrl}"></iframe>
            </div>`;

            content.innerHTML = content.innerHTML.replace(shortcode, youtubeEl);
          }
        }
      }
    },
    /**
     * @description Searches for [IMAGE] WP shortcodes and replaces them with the
     *              relevant image from the media library
     */
    generatePhotoShortcodes () {
      const content = document.querySelector(`.content__content`);
      if (content) {
        const shortcodeStrings = content.innerHTML.match(/\[[^[]*PHOTO [^\]]*\]/g);
        if (shortcodeStrings) {
          for (const shortcode of shortcodeStrings) {
            const imageId = shortcode.match(/\d+/)[0];
            this.getShortcodeImage(shortcode, imageId, content);
          }
        }
      }
    },
    /**
     * @description Searches the media library for the correct image based on the ID
     *              set in the shortcode, and replace the relevant element
     * @param       String shortcodeElement - An element string to be replaced
     * @param       String imageID - The image ID used in the shortcode
     * @param       String content - The post content
     */
    async getShortcodeImage (shortcodeElement, imageId, content) {
      const queryParams = {
        fields: `id,title,filepath`,
        posts: {
          post_type: [`attachment`],
          post_mime_type: `image`,
          posts_per_page: 1,
          post_status: `any`,
          s: imageId,
        },
      };

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        const shortcodeImage = request[0];
        if (shortcodeImage.filepath) {
          content.innerHTML = content.innerHTML.replace(shortcodeElement, `<img src="${shortcodeImage.filepath[0]}"/>`);
        } else {
          content.innerHTML = content.innerHTML.replace(shortcodeElement, ``);
        }
      } else {
        content.innerHTML = content.innerHTML.replace(shortcodeElement, ``);
      }
    },
    checkAdminLinks () {
      const urls = process.env.adminUrls;
      const adminLinks = document.querySelectorAll(`[href*="${urls.admin}"]`);
      for (const link of adminLinks) {
        const url = link.getAttribute(`href`);
        const newUrl = url.replace(urls.admin, urls.live);
        link.setAttribute(`href`, newUrl);
      }
    },
    // Inject DFP into this content block
    renderDfp () {
      /*
        This function will inject a DFP ad into the content block, before rendering the
        scripts within it
      */
      const dfp = document.createElement(`div`);
      dfp.classList.add(`max-article-width`, `px-1`, `py-2`, `ta-centre`);
      const leaderboardAd = this.siteSettings?.leaderboard_ad?.leaderboard_ad;
      const position = this.post.acf.dfp_options.override_position
        ? this.post.acf.dfp_options.position
        : this.$store.state.config.dfp_news_article_position;

      if (leaderboardAd && position) {
        dfp.id = leaderboardAd.element_id;
        const dfpScript = document.createElement(`script`);
        // set script innerhtml

        // NOTE: Following doesn't like being in a multiline template string
        dfpScript.innerHTML = `window.googletag = window.googletag || { cmd: [] };` +
          `window.googletag.pubads().refresh();` +
          `window.googletag.cmd.push(() => {` +
          `window.googletag.display('${leaderboardAd.element_id}');` +
          `});`;

        dfp.appendChild(dfpScript);

        const paragraphs = this.domQsa(`[data-block-id="${this.blockId}"] p:not(.blank)`);

        if (paragraphs.length) {
          if (paragraphs.length < parseInt(position)) {
            paragraphs[paragraphs.length - 1].after(dfp);
          } else {
            paragraphs[parseInt(position) - 1].after(dfp);
          }
        }
      }

      this.renderContentScripts();
    },
  },
};
