
export default {
  computed: {
    user () {
      return this.$store.state.auth?.user;
    },
    membership () {
      return this.$store.state.auth?.membership;
    },
  },
  methods: {
    soticSALink (path, type) {
      let link = `${process.env.tenantPortal}/${path}/${type}`;
      const options = {
        tenantid: process.env.tenantId,
        returnvisitorurl: encodeURIComponent(window.location.href),
        successredirecturl: encodeURIComponent(window.location.href),
        mandatory: true,
      };

      for (const [key, value] of Object.entries(options)) {
        const delimiter = key === `tenantid` ? `?` : `&`;
        link = link.concat(`${delimiter}${key}=${value}`);
      }
      return link;
    },
    /**
         * @description Clears site storage and then redirects user to portal logout
         * @return void
         */
    soticSALogout (logoutAction) {
      this.$cookies.remove(`aberdeen_sotic_sa_user`);
      this.$cookies.remove(`aberdeen_sotic_sa_token`);
      localStorage.removeItem(`sotic_sa`); // Remove overhanging login status from previous site
      window.location = logoutAction;
    },
  },
};
