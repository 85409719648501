
export default {
  data () {
    return {
      childActive: false,
      extractedDropdownParent: {
        title: ``,
        slug: ``,
      },
      title: `Dons Account`,
    };
  },
  methods: {
    openChild () {
      this.childActive = true;
      this.$emit(`child-open`);
    },
    closeChild () {
      this.childActive = false;
      this.$emit(`child-close`);
    },
  },
};
