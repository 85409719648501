import { render, staticRenderFns } from "./AccountMenu.vue?vue&type=template&id=e4ffb096&scoped=true&"
import script from "./AccountMenu.vue?vue&type=script&lang=js&"
export * from "./AccountMenu.vue?vue&type=script&lang=js&"
import style0 from "./AccountMenu.vue?vue&type=style&index=0&id=e4ffb096&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ffb096",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConditionalLink: require('/src/components/ConditionalLink/conditionalLink.vue').default,SoticSAMenu: require('/src/components/SoticSA/SoticSAMenu.vue').default,Submenu: require('/src/components/Header/Sections/Submenu/Submenu.vue').default})
