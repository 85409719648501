
export default {
  data () {
    return {
      memberDropdownActivated: false,
    };
  },
  methods: {
    handleMemberDropdown (state) {
      this.memberDropdownActivated = state;
    },
  },
};
