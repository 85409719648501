
export default {
  data () {
    return {
      partners: [],
      sitePartner: ``,
    };
  },
  computed: {
    headerPartners () {
      const headerPartners = this.$store.state.config.header_partner_tier;
      return headerPartners.map(partner => partner.ID);
    },
    sitePartnerObj () {
      return this.$store.state.config.header_site_partner;
    },
    sitePartnerTitle () {
      return this.$store.state.config.header_site_partner_title;
    },
  },
  mounted () {
    this.getPartners();
    this.getSitePartner();
  },
  methods: {
    async getPartners () {
      const queryParams = {
        fields: `id,title,acf,image,slug`,
        posts: {
          post_type: `partner`,
          post_status: `publish`,
          post__in: this.headerPartners,
          cat: this.categories,
          orderby: `menu_order`,
          order: `DESC`,
        },
        options: {
          image_size: `medium_large`,
        },
      };
      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.partners = request;
      }
    },
    async getSitePartner () {
      const queryParams = {
        fields: `id,title,acf,image,slug`,
        posts: {
          post_type: `partner`,
          post_status: `publish`,
          post__in: this.sitePartnerObj,
          orderby: `menu_order`,
        },
        options: {
          image_size: `medium_large`,
        },
      };
      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.sitePartner = request;
      }
    },
  },
};
