/* eslint-disable eqeqeq */
/* eslint-disable default-param-last */
/*
 *   Logic relating to retrieving data from the stats API, and applying it to fixtures, players, squads, etc.
 */

import Vue from "vue";
import feed from "~/mixins.js/feed.js";

Vue.mixin(feed);

export default {
  data() {
    return {};
  },
  computed: {
    competitionColours() {
      return this.$store.state.config.competition_colours;
    },
    defaultColours() {
      return this.$store.state.config.default_competition_colours;
    },
    defaultTeam() {
      return this.getDefault(`team`);
    },
    defaultCalendarPeriod() {
      return this.getDefault(`calendarperiod`);
    },
    defaultSeason() {
      return this.getDefault(`season`);
    },
    selectedTeamFromCms() {
      return (
        this.post?.acf?.fixture_list_config?.team?.team?.[0] ??
        this.getDefault(`team`)
      );
    },
    allTeams() {
      const men = this.getDefault(`team`);
      const women = this.getDefault(`team`, `women`);
      return [
        { title: `Men`, ...men },
        { title: `Women`, ...women },
      ];
    },
    chartColours() {
      return [`#b5050f`, `#ce521c`, `#e5832b`, `#34708c`, `#2c5473`, `#23395b`];
    },
    radarColours() {
      return [
        `rgba(181,5,15,0.75)`,
        `rgba(52, 112, 140, 0.75)`,
        `rgba(229, 131, 43,0.75)`,
      ];
    },
  },
  methods: {
    getCalendarPeriods() {
      return this.asyncResponseHandler(
        async () => await this.$getStats(`calendarPeriod`)
      );
    },
    getSeasons() {
      return this.asyncResponseHandler(
        async () => await this.$getStats(`season`)
      );
    },
    getFixtureVenue(fixture) {
      return fixture?.venue?.venueNames.length
        ? fixture.venue.venueNames[0].displayName
        : null;
    },
    getFixtureDate(fixture) {
      if (fixture) {
        return this.getDate(fixture?.datetime);
      }
      return null;
    },
    getFixtureTime(fixture) {
      return fixture?.timeToBeConfirmed
        ? `TBC`
        : fixture?.datetime
        ? this.getLocalTime(fixture?.datetime)
        : null;
    },
    getFixtureTimezone(fixture) {
      const datetime = new Date(fixture.datetime)
      return datetime.toLocaleString([], {timeZoneName: 'short'}).split(' ').pop()
    },
    getFixtureTwelveHourTime(fixture) {
      return this.getLocalTime(fixture?.datetime, `h:mm A`) ?? null;
    },
    getFixtureCompetition(fixture) {
      return fixture?.stage?.season?.displayName ?? null;
    },
    getFixtureScore(fixture, scoreType = `score`, source) {
      const teams = fixture?.fixtureTeams;
      const homeTeam = teams.find((team) => team.side.name === `Home`);
      const awayTeam = teams.find((team) => team.side.name === `Away`);

      const homeScore = this.isFixturePostponed(fixture)
        ? `P`
        : homeTeam[scoreType]; // Abandoned fixtures should still have a score
      const awayScore = this.isFixturePostponed(fixture)
        ? `P`
        : awayTeam[scoreType];

      // don't want to return fullTimeScore if the match has ended at FT
      if (
        !this.hasMatchGoneToExtraTime(fixture) &&
        scoreType === `fullTimeScore` &&
        source != `fixture_list`
      ) {
        return null;
      }

      if (homeScore && awayScore) return { home: homeScore, away: awayScore };
      return { home: `0`, away: `0` };
    },
    getFixtureBroadcastLink(broadcaster) {
      if (!broadcaster) return null;
      return {
        url: broadcaster.url || ``,
        title: `${broadcaster?.broadcaster?.name} link`,
      };
    },
    getFixtureBroadcastImage(broadcaster) {
      if (!broadcaster) return null;

      const broadcastImage = broadcaster?.broadcaster?.images[0] ?? null;

      if (!broadcastImage) return null;

      const altText = `${broadcaster?.broadcaster?.name ?? `broadcaster`}`;

      return {
        url: broadcastImage?.url,
        title: altText,
        alt: altText,
      };
    },
    getFixtureAnnotation(fixture) {
      return this.isFixturePostponed(fixture)
        ? `Match Postponed`
        : this.isFixtureAbandoned(fixture)
        ? `Match Abandoned`
        : ``;
    },
    getFixtureState(fixture) {
      return fixture?.state?.displayName ?? false;
    },
    doesFixtureHaveABroadcaster(fixture) {
      return fixture?.fixtureBroadcasts?.broadcaster?.id ?? false;
    },
    isFixturePreMatch(fixture) {
      return fixture?.state?.statusName === `PreMatch`;
    },
    isFixtureInPast(fixture) {
      return (
        fixture?.state?.statusName === `FullTime` ||
        this.$dayjs().isAfter(fixture?.datetime) ||
        false
      );
    },
    isFixtureInFuture(fixture) {
      return (
        [`PreMatch`].includes(fixture?.state?.statusName) || false
      );
    },
    isFixtureLive(fixture) {
      return fixture?.state?.statusName === (`Live` || ``) || false;
    },
    isFixtureFullTime(fixture) {
      return fixture?.state?.statusName === `FullTime` || false;
    },
    isFixtureAbandoned(fixture) {
      return fixture?.state?.statusName === `Abandoned` || false;
    },
    isFixturePostponed(fixture) {
      return fixture?.state?.statusName === `Postponed` || false;
    },
    hasMatchGoneToExtraTime(fixture) {
      return (
        fixture?.fixtureTeams.every((team) => team.extraTimeScore) || false
      );
    },
    getAggregateScore(fixture) {
      if (fixture.firstLegFixture?.id === ``) {
        return false
      }
      const firstLegFixture = fixture?.firstLegFixture;
      const secondLegFixture = fixture;

      const teamIds = () => {
        const teamIds = [];
        firstLegFixture.fixtureTeams.forEach((team) =>
          teamIds.push(team.teamId)
        );
        return teamIds;
      };

      const aggregateScores = () => {
        const aggregateScores = [];
        teamIds().forEach((teamId) => {
          const firstLegScore = firstLegFixture.fixtureTeams.find(
            (team) => team.teamId === teamId
          ).score;
          const secondLegScore = secondLegFixture.fixtureTeams.find(
            (team) => team.teamId === teamId
          ).score;

          const teamAggregateScore = {
            id: teamId,
            aggregateScore: 0,
            calcAggregateScore() {
              this.aggregateScore =
                parseInt(firstLegScore) + parseInt(secondLegScore);
            },
          };

          teamAggregateScore.calcAggregateScore();
          return aggregateScores.push(teamAggregateScore);
        });
        return aggregateScores;
      };

      function assignAggregateScoresToSecondLegTeams() {
        const homeTeam = secondLegFixture.fixtureTeams.find(
          (team) => team.side.name === `Home`
        );
        const awayTeam = secondLegFixture.fixtureTeams.find(
          (team) => team.side.name === `Away`
        );

        function findCorrespondingAggregateScore(side) {
          return aggregateScores().find((team) => team.id === side.teamId)
            .aggregateScore;
        }

        const homeTeamAggregateScore =
          findCorrespondingAggregateScore(homeTeam);
        const awayTeamAggregateScore =
          findCorrespondingAggregateScore(awayTeam);

        if (homeTeamAggregateScore && awayTeamAggregateScore) {
          return {
            homeAggregate: homeTeamAggregateScore,
            awayAggregate: awayTeamAggregateScore,
          };
        }
      }

      if (firstLegFixture && secondLegFixture) {
        return assignAggregateScoresToSecondLegTeams();
      }
      return ``;
    },

    getRelativeResult(fixture, selectedTeamId) {
      if (fixture.state.statusName === `Postponed`) {
        return `Postponed`
      }
      const team = fixture?.fixtureTeams?.find(
        (team) => team.team.id === selectedTeamId
      );
      const opposition = fixture?.fixtureTeams?.find(
        (team) => team.team.id !== selectedTeamId
      );

      if (team && opposition) {
        if (fixture?.winnerFixtureTeamId) {
          return fixture.winnerFixtureTeamId === team.id ? `Win` : `Loss`;
        }
        if (fixture.state.statusName === `Live`) {
          if (team.score < opposition.score) {
            return `Losing`;
          } else if (team.score > opposition.score) {
            return `Winning`;
          }
          return ``;
        }
        return `Draw`;
      }
      return ``;
    },
    async getLatestResult({ id, assignmentFn }) {
      // NOTE: this method is intended to be used for `live` reactivity purposes
      if (!id) return null;
      const paramArray = [
        `firstLegFixture`,
        `venue`,
        `state`,
        `socialLinks.socialType`,
        `stage.season.competition`,
        `fixtureTeams.team.images`,
        `fixtureBroadcasts.broadcaster.images`,
        `fixtureBroadcasts.fixture`,
      ].join(`,`);

      const response = await this.$getStats(`fixture/${id}`, {
        include: paramArray,
      }).catch((_) => null);

      if (!response) return null;

      assignmentFn({ response });
    },
    getPostFullTimeAnnotation(fixture) {
      return fixture?.fixtureTeams?.every((team) => team.extraTimeScore)
        ? `(AET)`
        : ``;
    },
    getOpposition(fixture) {
      const oppositionTeam = fixture?.fixtureTeams?.find(
        (team) => team.teamId !== this.defaultTeam.id
      );
      return oppositionTeam;
    },
    getOppositionFromSelectedTeam(fixture, selectedTeamId) {
      const oppositionTeam = fixture?.fixtureTeams?.find(
        (team) => team.teamId !== selectedTeamId
      );
      return oppositionTeam;
    },
    getClubLogo(team, light = false) {
      if (team?.images?.length) {
        const desc = light ? `logoLight` : `logoDark`;
        const src = team.images.find((image) => image.description === desc);

        return {
          url: src ? src.url : team?.images[0]?.url ?? ``,
          title: team?.teamNames.length
            ? `${team.teamNames[0].displayName} logo`
            : `team logo`,
          alt: team?.teamNames.length
            ? `${team.teamNames[0].displayName} logo`
            : `team logo`,
        };
      }
      return null;
    },
    getCompetitionColours(fixture = null, competitionId = null) {
      let compId = competitionId;
      if (fixture) {
        compId = fixture?.stage?.season?.competitionId;
      }

      for (const comp of this.competitionColours) {
        if (
          [...comp.competition.competition].find(
            (competition) => competition.id === compId
          )
        ) {
          return {
            background: comp.background_colour,
            color: comp.text_colour,
          };
        }
      }
      return {
        background: this.defaultColours.background_colour,
        color: this.defaultColours.text_colour,
      };
    },
    getSideLabel(fixture, selectedTeamId) {
      return (
        fixture?.fixtureTeams
          ?.find((team) => team.teamId === selectedTeamId)
          ?.side?.name.charAt(0) ?? `N`
      );
    },
    getSideFromFixture(fixture, selectedTeamId) {
      const selectedTeam =
        fixture?.fixtureTeams?.find((team) => team.teamId === selectedTeamId) ??
        null;
      if (!selectedTeam) return null;
      const side = { key: `side` };
      return selectedTeam?.neutral
        ? { ...side.name, value: `Neutral` }
        : { ...side.name, value: selectedTeam.side.name };
    },
    getKnownAs(player, lastName = false, abbreviatedFirst = false) {
      if (player) {
        return player.knownAs
          ? player.knownAs
          : lastName
          ? player.lastName
          : abbreviatedFirst && player.firstName
          ? `${player.firstName.charAt(0)}. ${player.lastName}`
          : `${player.firstName} ${player.lastName}`;
      }
      return null;
    },
    getTeam(fixture, side = `Home`) {
      return fixture?.fixtureTeams?.find((team) => team?.side?.name === side);
    },
    isSelectedTeamFromCms(team, selectedTeamId) {
      return team.id === selectedTeamId || false;
    },
    formatStat(value) {
      // Round to at most 2 decimal places
      const formattedValue = Math.round((value + Number.EPSILON) * 100) / 100;

      // TODO: Add check for percentage output
      return formattedValue;
    },
    getKeyEventTypes() {
      const eventTypes =
        this.siteSettings?.key_match_events?.match_events?.matcheventtype;
      const substitutions =
        this.siteSettings?.key_match_events?.include_substitutions;
      return {
        eventTypes,
        substitutions,
      };
    },
    getFixtureTeamName(team) {
      return team?.team?.teamNames[0].displayName;
    },
    getCalendarLink({ entity, id }) {
      /* const baseCalendarLink = `webcal://${process.env.statsAPIUrl.replace(
        `https://`,
        ``
      )}/custom/ical?entity_type=${entity}&entity_ids=${id}`; */

      const isAndroid = /android/.test(navigator.userAgent);
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isAndroid || isIOS) {
        const baseCalendarLink = `webcal://${process.env.statsAPIUrl.replace(
          `https://`,
          ``
        )}/custom/ical?entity_type=${entity}&entity_ids=${id}`;
        return `https://www.google.com/calendar/render?cid=${baseCalendarLink}`;
      } else {
        // const encodedBaseCalendarLink = encodeURIComponent(baseCalendarLink);
        // return `data:text/calendar;charset=utf-8,${encodedBaseCalendarLink}`;
        // TEMP: Temp fix, requires further investigation
        const baseCalendarLink = `${process.env.statsAPIUrl}/custom/ical?entity_type=${entity}&entity_ids=${id}`;
        return baseCalendarLink;
      }
    },
    getTicketsLink(fixture) {
      return (
        fixture?.socialLinks?.find(
          (socialLink) => socialLink.socialType?._name === `tickets`
        )?.target ?? null
      );
    },
    getLatestHeadshot(player) {
      if (player?.images?.length) {
        const headshots = player.images.filter(image => image.description === `headshot`);

        if (headshots.length) {
          headshots.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          });
          return headshots[0].url;
        }
      }
      return this.siteSettings.default_player_image.sizes.large
    },
  },
};
