import consts from "./includes/const";

export default {
  methods: {
    // ======================================
    // STRING METHODS
    // ======================================
    /*
     *  Get excerpt from string
     */
    getExcerpt (excerpt, excerptLength) {
      const strippedExcerpt = excerpt.replace(/(<([^>]+)>)/gi, ``);
      const excerptArr = strippedExcerpt.split(` `);
      if (excerptArr.length > excerptLength) {
        return `${excerptArr.slice(0, excerptLength).join(` `)}...`;
      }
      return strippedExcerpt;
    },
    /*
     *  Convert string to kebab-case to use as page slugs and Vue component IDs
     */
    slugify (text) {
      return text
        .replace(/[^A-Za-z0-9\s_]/g, ``)
        .replace(/[_\s]/g, `-`)
        .replace(`/-+/`, `-`, this)
        .toLowerCase();
    },
    /*
     *  Convert kebab-case to title case
     */
    deslugify (text) {
      const deslugged = text
        .replace(/-/g, ` `)
        .replace(/_/g, ` `)
        .toLowerCase();
      return this.capitalise(deslugged);
    },
    /*
     *  Convert string to array of words
     */
    splitTitle (title) {
      return title.split(` `);
    },
    /*
     *  Capitalise every word in a string
     */
    capitalise (text) {
      const textArr = text.split(` `);
      const capitalised = textArr.map(
        word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      );
      return capitalised.join(` `);
    },
    /*
     *  Extract substring from between two other substrings
     *  e.g. extractString(iframeString, `src="`, `"`) would return the src URL of an iframe
     */
    extractString (text, start, end) {
      return text.split(start)[1].split(end)[0];
    },
    truncateString (text, length) {
      if (text) {
        return text.length > length ? `${text.substring(0, length)}...` : text;
      }
      return ``;
    },
    decodeHtml (html) {
      const txt = document.createElement(`textarea`);
      txt.innerHTML = html;
      return txt.value;
    },
    // ======================================
    // NUMBER METHODS
    // ======================================
    /*
     *  Get ordinal of number e.g. st, nd, rd, th
     */
    ordinal (num) {
      const number = parseInt(num);
      return `${num}${
        [`st`, `nd`, `rd`][((((number + 90) % 100) - 10) % 10) - 1] || `th`
      }`;
    },
    /*
     *  Get compact number e.g. 123456 => 123K, 1234.56 => 1.2K
     */
    compactNumber (number) {
      return consts.COMPACT_NUMBER_FORMATTER.format(number);
    },

    // ======================================
    // ARRAY/OBJECT METHODS
    // ======================================
    /*
     *  Split data into pages; nested arrays with set maximum lengths
     */
    paginateData (data, perPage) {
      const pages = [];
      for (let i = 0; i < data.length; i += perPage) {
        pages.push(data.slice(i, i + perPage));
      }
      return pages;
    },
    /*
     *  Remove falsey values from array: false, undefined, null, '', 0, NaN
     */
    declutter (data) {
      const result = [];
      for (const value of data) {
        if (value) {
          result.push(value);
        }
      }
      return result;
    },
    /*
    *  Remove primitive values or objects from an array, the selected values must be as an array e.g. [6], ['a'], [object]
    */
    arrayCopy (arr) {
      return arr.slice();
    },
    /*
    *  Make a deep object copy, disconnecting the reference to original
    */
    objectDeepCopy (obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    /*
    *  Resolves object based on a provided path (e.g., 'lineup.player._name')
    */
    objectDeepLookupByPath (obj, path) {
      const pathItems = path.split(`.`);
      if (!obj) return;
      if (pathItems.length === 1) {
        return obj[pathItems[0]];
      }
      return this.objectDeepLookupByPath(obj[pathItems[0]], pathItems.slice(1).join(`.`));
    },
    /*
    * Resolves object based on a provided path, and can handle arrays of nested objects by
    * comparing the relevant prop value to a specified, expected value
    */
    objectDeepLookupByPathAdvanced ({ expectedValue }) {
      return (obj, path) => {
        const pathItems = path.split(`.`);
        const truncatePathByOneLevel = pathItems.slice(1).join(`.`);

        if (!obj) return;

        if (pathItems.length === 1) {
          if (obj[pathItems[0]] === expectedValue || expectedValue === null) {
            return obj[pathItems[0]];
          }
          return;
        }

        if (Array.isArray(obj)) {
          if (pathItems[pathItems.length - 1] === `_name` || expectedValue === null) { // TODO: add ability to specify other keys in addition to `_name` with an `expectedKey` argument
            return this.objectDeepLookupByPath(
              obj.find(objItem =>
                this.objectDeepLookupByPathAdvanced({ expectedValue })(
                  objItem[pathItems[0]],
                  truncatePathByOneLevel,
                ),
              ),
              pathItems.join(`.`),
            );
          }
          return obj.find(objItem =>
            this.objectDeepLookupByPathAdvanced({ expectedValue })(
              objItem[pathItems[0]],
              truncatePathByOneLevel,
            ),
          );
        }

        return this.objectDeepLookupByPathAdvanced({ expectedValue })(
          obj[pathItems[0]],
          truncatePathByOneLevel,
        );
      };
    },
    /*
    *  Remove primitive values or objects from an array, the selected values must be as an array e.g. [6], ['a'], [object]
    */
    removeFromArray (data, remove = []) {
      const result = [];
      for (const value of data) {
        if (!remove.includes(value)) {
          result.push(value);
        }
      }
      return result;
    },
    /*
     * Get object value by string path e.g. 'country.id'
     */
    resolveObjectPath (data, path) {
      return path.split(`.`).reduce((a, v) => a[v], data);
    },
    /*
     *  Sort a nested array by string, use dot notation e.g. 'country.name'
     */
    sortNestedString (data, sortBy, order = `asc`) {
      return order === `asc`
        ? data.sort((a, b) =>
          this.resolveObjectPath(a, sortBy).localeCompare(
            this.resolveObjectPath(b, sortBy),
          ),
        )
        : data.sort((a, b) =>
          this.resolveObjectPath(b, sortBy).localeCompare(
            this.resolveObjectPath(a, sortBy),
          ),
        );
    },
    /*
     *  Sort a nested array by number, use dot notation e.g. 'country.info.points'
     */
    sortNestedNumber (data, sortBy, order = `asc`) {
      return order === `asc`
        ? data.sort(
          (a, b) =>
            parseFloat(this.resolveObjectPath(a, sortBy)) -
              parseFloat(this.resolveObjectPath(b, sortBy)),
        )
        : data.sort(
          (a, b) =>
            parseFloat(this.resolveObjectPath(b, sortBy)) -
              parseFloat(this.resolveObjectPath(a, sortBy)),
        );
    },
    /*
     *  Group array items by key values
     */
    groupBy (array, key) {
      return array.reduce((group, element) => {
        const keyValue = element[key];
        return {
          ...group,
          [keyValue]: [...(group[keyValue] ?? []), element],
        };
      }, {});
    },

    // ======================================
    // STYLING METHODS
    // ======================================
    /*
     *  Get image position set in the post, defaults to centre
     */
    getImagePosition (post) {
      return post.acf && post.acf.featured_image_position
        ? post.acf.featured_image_position
        : `center center`;
    },
    /*
     *  Convert hex colour to {r, g, b}
     */
    hexToRGB (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
    },
    /*
     *  Get text colour based on the background
     */
    getTextColour (backgroundClass) {
      return (!backgroundClass || [`bg-grey200`, `bg-grey100`, `bg-white`].includes(backgroundClass))
        ? `c-black`
        : `c-white`;
    },
    getTextHoverColour (backgroundClass) {
      return [`bg-grey200`, `bg-grey100`, `bg-white`].includes(backgroundClass)
        ? `hover-c-primary`
        : `hover-c-grey200`;
    },

    // ======================================
    // DATE METHODS
    // ======================================
    /*
     *  Get date from date/time string or object
     *  https://day.js.org/docs/en/display/format
     */
    getDate (date, format = `DD MMMM YYYY`) {
      return this.$dayjs(date).format(format);
    },
    /*
     *  Get local time from date/time string or object
     */
    getLocalTime (date, format = `HH:mm`) {
      return this.$dayjs(date).local()
        .format(format);
    },
    /*
     *  Get readable relative date in user's language e.g. '1 hour ago', 'last week'
     */
    relativeDate (fromDate, toDate = null) {
      const from = this.$dayjs(fromDate);
      const to = toDate ? this.$dayjs(toDate) : this.$dayjs();
      // e.g.
      // from > to => 'in 1 hour'
      // from < to => '1 hour ago'
      return from.from(to);
    },
    formatZeros (num) {
      return num > 9 ? num : `0${num}`;
    },
    // ======================================
    // DOM METHODS
    // ======================================
    /*
     *  Query Selector
     */
    domQs (selector, parent = document) {
      return parent.querySelector(selector);
    },
    /*
     *  Query Selector All, converted from nodelist to array
     */
    domQsa (selector, parent = document) {
      return [...parent.querySelectorAll(selector)];
    },
    // ======================================
    // MISC. METHODS
    // ======================================
    /*
     *   Get flag SVG by country code, run function with no country code to get placeholder
     */
    getFlag (countryCode) {
      return countryCode
        ? `${process.env.flagUrls.square}/${countryCode}.svg`
        : `${process.env.flagUrls.round}/placeholder-flag.svg`;
    },
    /*
     *   Use with @error on flag images to use placeholder when flag isn't available
     */
    flagFallback (e) {
      e.target.src = this.getFlag();
    },
    /*
     *  Run function after specific time using .sleep(500).then(function);
     */
    sleep (duration) {
      return new Promise(resolve => {
        setTimeout(resolve, duration);
      });
    },
    /*
     *  Output a group of console commands, useful for logging related data to console
     */
    consoleGroup (items = [], name = `Group`, type = `log`) {
      /* eslint-disable no-console */
      console.group(name);
      for (const item of items) {
        console[type](item);
      }
      console.groupEnd(name);
    },
    /*
    *  Evaluate whether a link title is `AberDNA`, or `RedTV`, and convert them into voice-reader-friendly strings
    */
    voiceReaderFriendlyString (input) {
      let output = input;
      for (const override of consts.VOICEOVER_OVERRIDES) {
        output = output.replaceAll(override.find, override.replace).trim();
      }
      return output;
    },
    // ======================================
    // HIGHER-ORDER METHODS
    // ======================================
    /*
     *  Async Response Handler
     */
    async asyncResponseHandler (fn) {
      const response = await fn().catch(_ => null);
      if (!response) return null;
      return response;
    },
    settleAllRequestPromisesAndAssignResults ({ promisesArray }) {
      return async ({ fnResultFormat, fnResultOutput }) => await Promise.allSettled(promisesArray).then(
        requestPromisesResults => {
          requestPromisesResults.forEach(result => {
            const resultValue = fnResultFormat(result);
            if (resultValue !== null) {
              fnResultOutput(resultValue);
            }
          });
        },
      );
    },
  },
  mounted () {
    // Testing here
  },
};
