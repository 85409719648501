import axios from 'axios';

export default (context, inject) => {
  // Get membership of logged in user
  const membership = async token => {
    if (token) {
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': `application/json`,
          'Content-Type': `application/json`,
        },
      };
      try {
        const request = await axios.get(`${process.env.sportsAllianceUrl}/member`, config);
        return request.data;
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    } else {
      return null;
    }
  };

  inject(`getMembership`, membership);
  context.$getMembership = membership; // Backwards compatibility for Nuxt <= 2.12
};
