
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isMpu: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      slot: null,
    };
  },
  computed: {
    elementId () {
      return this.data?.element_id;
    },
    slotId () {
      return this.data?.slot_id;
    },
    width () {
      return this.data?.width;
    },
    height () {
      return this.data?.height;
    },
    style () {
      return {
        width: `100%`,
        maxWidth: `${this.width}px`,
        maxHeight: `${this.height}px`,
        aspectRatio: `${this.width}/${this.height}`,
      };
    },
  },
  mounted () {
    // settimeout run initDFP
    setTimeout(() => {
      this.initDFP();
    }, 2000);
  },
  methods: {
    initDFP () {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.pubads().refresh();
      window.googletag.cmd.push(() => {
        window.googletag.display(this.elementId);
      });
    },
    refreshSlot () {
      // DEBUG: Refresh slot
      window.googletag.pubads().refresh();
      window.googletag.cmd.push(() => {
        window.googletag.display(this.elementId);
      });
    },
  },
};
