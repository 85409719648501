export default {
  data () {
    return {
      // Formation mapping, formation number to position (left to right)
      // Single number is general formation, array is specific formation
      formationMap: {
        "DF": {
          "3": [4, 5, 6],
          "4": [3, 6, 5, 2],
          "5": [3, 4, 5, 6, 2],
          "3142": [6, 4, 5],
        },
        "DMF": {
          "1": [4],
          "2": [3, 2], // 3-2-4-1
          "343d": [8],
          "41212": [4],
          "3142": [8],
        },
        "MF": {
          "2": [4, 8],
          "3": [8, 4, 7],
          "4": [11, 8, 4, 7],
          "5": [3, 8, 11, 7, 2],
          "41212": [7, 11],
          "4222": [8, 4],
          "424": [8, 4],
          "343d": [3, 2],
          "4321": [7, 4, 8],
          "4312": [11, 4, 7],
          "4132": [11, 8, 7],
          "3331": [3, 8, 2],
          "4141": [11, 10, 8, 7],
          "532": [7, 8, 11],
          "541": [11, 10, 8, 7],
          "343": [3, 8, 7, 2],
          "3412": [3, 8, 7, 2],
          "3421": [3, 8, 7, 2],
          "3142": [3, 11, 7, 2],
          "3241": [11, 8, 7, 10],
          "451": [11, 8, 10, 4, 7],
          "4240": [8, 4],
        },
        "AMF": {
          "1": [8],
          "2": [11, 10],
          "3": [11, 10, 7],
          "343d": [7],
          "4312": [8],
          "3412": [9],
          "4411": [10],
          "3511": [10],
          "4222": [11, 7],
          "3331": [11, 7, 10],
        },
        "ST": {
          "1": [9],
          "2": [9, 10],
          "3": [11, 9, 10],
          "4": [11, 10, 9, 7],
          "3412": [11, 10],
          "3142": [10, 9],
          "4132": [10, 9],
          "4312": [10, 9],
        },
      },
      // Positions mapping for CSS styling e.g. "LWB" will be pushed forward
      linePositions: {
        GK: [`GK`],
        DF: {
          3: [`CB`, `CB`, `CB`],
          4: [`LB`, `CB`, `CB`, `RB`],
          5: [`LWB`, `CB`, `CB`, `CB`, `RWB`],
        },
        DMF: {
          1: [`CDM`],
          2: [`CDM`, `CDM`],
          3: [`CDM`, `CDM`, `CDM`],
        },
        MF: {
          1: [`CM`],
          2: [`CM`, `CM`],
          3: [`CM`, `CM`, `CM`],
          4: [`LM`, `CM`, `CM`, `RM`],
          5: [`LM`, `CM`, `CM`, `CM`, `RM`],
        },
        AMF: {
          1: [`CAM`],
          2: [`CAM`, `CAM`],
          3: [`CAM`, `CAM`, `CAM`],
        },
        ST: {
          1: [`ST`],
          2: [`ST`, `ST`],
          3: [`LW`, `ST`, `RW`],
          4: [`LW`, `ST`, `ST`, `RW`],
        },
      },
      // Line mapping, which position groups are in which line
      formationLayouts: {
        /*
        * By default we have
        *  - 3 numbers: DF, MF, ST
        *  - 4 numbers: DF, MF, AMF, ST
        * If we have more lines (4-1-2-1-2) or need to override (4-1-4-1) put here
        */
        '41212': [`DF`, `DMF`, `MF`, `AMF`, `ST`],
        '4141': [`DF`, `DMF`, `MF`, `ST`],
        '4132': [`DF`, `DMF`, `MF`, `ST`],
        '3241': [`DF`, `DMF`, `MF`, `ST`],
        '31213': [`DF`, `DMF`, `MF`, `AMF`, `ST`],
        '3142': [`DF`, `DMF`, `MF`, `ST`],
      },
      // Some formations don't have intuitive names, so we can override them here
      formationLabels: {
        '343d': `31213`,
        '4240': `424`,
      },
      // All possible formations for testing
      formations: [
        `442`,
        `41212`,
        `433`,
        `451`,
        `4411`,
        `4141`,
        `4231`,
        `4321`,
        `532`,
        `541`,
        `352`,
        `343`,
        `4222`,
        `3511`,
        `3421`,
        `3412`,
        `3142`,
        `343d`,
        `4132`,
        `4240`,
        `4312`,
        `3241`,
        `3331`,
      ],
    };
  },
  methods: {
  },
};
