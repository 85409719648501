import axios from 'axios';
import qs from 'qs';

export default (context, inject) => {
  const stats = async (requestUrl, requestParams) => {
    try {
      const request = await axios.get(`${process.env.statsAPIUrl}/${requestUrl}`, {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params),
      });
      return request.data;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  const customStats = async (requestUrl, requestParams) => {
    try {
      const request = await axios.get(`${process.env.statsAPIUrl}/stats/custom/${requestUrl}`, {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params),
        maxRedirects: 0,
      });

      return request.data;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  inject(`getStats`, stats);
  context.$getStats = stats; // Backwards compatibility for Nuxt <= 2.12

  inject(`getCustomStats`, customStats);
  context.$getCustomStats = customStats; // Backwards compatibility for Nuxt <= 2.12
};
