import { render, staticRenderFns } from "./MenuList.vue?vue&type=template&id=7156c454&"
import script from "./MenuList.vue?vue&type=script&lang=js&"
export * from "./MenuList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConditionalLink: require('/src/components/ConditionalLink/conditionalLink.vue').default,NavigationSubmenu: require('/src/components/Header/Sections/Submenu/NavigationSubmenu.vue').default,Submenu: require('/src/components/Header/Sections/Submenu/Submenu.vue').default})
