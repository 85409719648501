import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71b035ac = () => interopDefault(import('../pages/match-centre.vue' /* webpackChunkName: "pages/match-centre" */))
const _2cb3e597 = () => interopDefault(import('../pages/set-token.vue' /* webpackChunkName: "pages/set-token" */))
const _73d71413 = () => interopDefault(import('../pages/boapartners/_id.vue' /* webpackChunkName: "pages/boapartners/_id" */))
const _9f596154 = () => interopDefault(import('../pages/gallery/_slug.vue' /* webpackChunkName: "pages/gallery/_slug" */))
const _3c65d4ae = () => interopDefault(import('../pages/live/_slug.vue' /* webpackChunkName: "pages/live/_slug" */))
const _d66058dc = () => interopDefault(import('../pages/match_preview/_slug.vue' /* webpackChunkName: "pages/match_preview/_slug" */))
const _22633f36 = () => interopDefault(import('../pages/report/_slug.vue' /* webpackChunkName: "pages/report/_slug" */))
const _32b92222 = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3df784b0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/match-centre",
    component: _71b035ac,
    name: "match-centre"
  }, {
    path: "/set-token",
    component: _2cb3e597,
    name: "set-token"
  }, {
    path: "/boapartners/:id?",
    component: _73d71413,
    name: "boapartners-id"
  }, {
    path: "/gallery/:slug?",
    component: _9f596154,
    name: "gallery-slug"
  }, {
    path: "/live/:slug?",
    component: _3c65d4ae,
    name: "live-slug"
  }, {
    path: "/match_preview/:slug?",
    component: _d66058dc,
    name: "match_preview-slug"
  }, {
    path: "/report/:slug?",
    component: _22633f36,
    name: "report-slug"
  }, {
    path: "/video/:id?",
    component: _32b92222,
    name: "video-id"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/*",
    component: _3df784b0,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
