
export default {
  props: {
    type: {
      type: String,
      default: `leaderboard`,
      validator: value => [`leaderboard`, `mpu`].includes(value),
    },
    leaderboard: {
      type: String,
      default: `primary`,
      validator: value => [`primary`, `secondary`].includes(value),
    },
  },
  computed: {
    dfpData () {
      return this.type === `leaderboard` ? this.leaderboardData : this.mpuData;
    },
    mpuData () {
      return this.siteSettings?.mpu_ad?.mpu_ad;
    },
    leaderboardData () {
      return this.leaderboard === `secondary`
        ? this.siteSettings?.secondary?.leaderboard_ad
        : this.siteSettings?.leaderboard_ad?.leaderboard_ad;
    },
  },
};
