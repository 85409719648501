export default {
  computed: {
    // The membership object returned from the API
    membership () {
      return this.$store.state.auth?.membership;
    },
    // Whether the user has an active membership package
    membershipActive () {
      return this.membership?.aberdna_active;
    },
    // Whether the user is an active season ticket holder
    seasonTicketActive () {
      return this.membership?.season_ticket_active;
    },
    // The current active membership package
    currentMembership () {
      if (this.membershipActive) {
        return this.membership.aberdna_type;
      }
      return null;
    },
    // The user ID for use with Red TV
    redTVUserId () {
      return this.$store.state.auth?.user?.ext_acc_ticketing;
    },
    ssoId () {
      return this.$store.state.auth?.user?.tenant_id;
    },
    // The field from which to retrieve DFPs
    membershipField () {
      let fieldName = ``;

      // If not logged in
      if (!this.$store.state.auth.token) {
        return `default`;
      }

      // Membership vs no membership (logged in)
      if (this.membershipActive) {
        // If user has active membership
        // TODO: map membership type to field name when list received
        fieldName += `aberdna_`;

        // If user has active season ticket
        fieldName += this.seasonTicketActive ? `sth` : this.currentMembership;
      } else {
        fieldName += `logged_in`;

        // If user has active season ticket
        fieldName += this.seasonTicketActive ? `_sth` : ``;
      }

      return fieldName;
    },

  },
};
