const state = () => ({
  user: null,
  membership: null,
  token: null,
});

const mutations = {
  SET_USER (state, data) {
    state.user = data;
  },
  SET_MEMBERSHIP (state, data) {
    state.membership = data;
  },
  SET_TOKEN (state, data) {
    state.token = data;
  },
};

const actions = {
  init ({ commit }) {
    /* const token = this.$cookies.get(`aberdeen_sotic_sa_token`);
    if (token) {
      commit(`SET_TOKEN`, token);
    }

    const user = this.$cookies.get(`aberdeen_sotic_sa_user`);
    if (user) {
      commit(`SET_USER`, user);
    } */
  },

  setUser ({ commit }, user) {
    commit(`SET_USER`, user);
  },
  setMembership ({ commit }, membership) {
    commit(`SET_MEMBERSHIP`, membership);
  },
  setToken ({ commit }, token) {
    commit(`SET_TOKEN`, token);
  },

};

const getters = {
  isAuthenticated (state) {
    return !!state.token;
  },
  hasUser (state) {
    return !!state.user;
  },
  hasMembership (state) {
    return !!state.membership;
  },
  getUser (state) {
    return state.user;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
