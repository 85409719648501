import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=1c4c7b1a&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=1c4c7b1a&prod&lang=scss&"
import style1 from "./Header.vue?vue&type=style&index=1&id=1c4c7b1a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c4c7b1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConditionalLink: require('/src/components/ConditionalLink/conditionalLink.vue').default,TopHeader: require('/src/components/Header/TopHeader.vue').default,DesktopNav: require('/src/components/Header/Sections/Navigation/DesktopNav.vue').default,MobileMenu: require('/src/components/Header/Sections/Mobile/MobileMenu.vue').default,AccountMenu: require('/src/components/Header/Sections/AberDNA/AccountMenu.vue').default,SiteSearch: require('/src/components/Header/Sections/Search/SiteSearch.vue').default,Header: require('/src/components/Header/Header.vue').default})
