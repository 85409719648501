
import Vue from "vue";
import global from "~/mixins.js/global.js";
import language from "~/mixins.js/language.js";
import wp from "~/mixins.js/wp.js";
import auth from "~/mixins.js/auth.js";
import stats from "~/mixins.js/stats.js";
import search from "~/mixins.js/search.js";
import formation from "~/mixins.js/formation.js";

Vue.mixin(global);
Vue.mixin(language);
Vue.mixin(wp);
Vue.mixin(auth);
Vue.mixin(stats);
Vue.mixin(search);
Vue.mixin(formation);

export default {
  data () {
    return {
      hasScrolled: false,
    };
  },
  computed: {
    searchStatus () {
      return this.$store.state.search.searchStatus;
    },
    showPopup () {
      return this.$store.state.config.show_popup_toggle;
    },
    enableOneTrust () {
      return this.$store.state.config.enable_onetrust;
    },
    enableSoticCookieBanner () {
      return this.$store.state.config.site_cookies.cookie_banner_active;
    },
    isIndexPage () {
      return this.$route.name === `index`;
    },
  },
  watch: {
    searchStatus (val) {
      val
        ? (document.querySelector(`body`).style.overflowY = `hidden`)
        : (document.querySelector(`body`).style.overflowY = `scroll`);
    },
  },
  beforeMount () {
    if (window.location.hostname === process.env.undesiredCanonical) {
      window.location.replace(
        process.env.desiredCanonical + window.location.pathname,
      );
    }
    // Get Language
    const lang = localStorage.getItem(`language`) || `en`;
    this.setLang(lang);
    this.$store.dispatch(`language/getStringTranslations`);

    // Get Config
    this.getAdditionalConfig();
  },
  mounted () {
    this.$store.dispatch(`language/getLanguages`, false);
  },
  methods: {
    handleScroll (val) {
      this.hasScrolled = val;
    },
    async getAdditionalConfig () {
      // Get additional config data
      const params = {
        fields: `acf,title`,
        posts: {
          post_type: [`additional_config`],
          posts_per_page: 50,
          post_status: `publish`,
        },
      };

      const config = await this.$getPosts(`/posts`, params);
      if (config !== null) {
        this.$store.dispatch(`wp/setAdditionalConfig`, config);
      }
    },
    renderDfp () {
      const dfp = document.createElement(`div`);
      dfp.classList.add(`max-article-width`, `px-1`, `py-2`, `ta-centre`);
      const leaderboardAd = this.siteSettings?.leaderboard_ad?.leaderboard_ad;
      const position = this.post.acf.dfp_options.override_position
        ? this.post.acf.dfp_options.position
        : this.$store.state.config.dfp_news_article_position;

      if (leaderboardAd) {
        dfp.id = leaderboardAd.element_id;
        const dfpScript = document.createElement(`script`);
        // set script innerhtml

        // NOTE: Following doesn't like being in a multiline template string
        dfpScript.innerHTML = `window.googletag = window.googletag || { cmd: [] };` +
          `window.googletag.pubads().refresh();` +
          `window.googletag.cmd.push(() => {` +
          `window.googletag.display('${leaderboardAd.element_id}');` +
          `});`;

        dfp.appendChild(dfpScript);

        const blocks = this.domQsa(`.flexible-content .flexible-content__block`);

        if (blocks.length < parseInt(position)) {
          blocks[blocks.length - 1].after(dfp);
        } else {
          blocks[parseInt(position) - 1].after(dfp);
        }

        this.renderContentScripts();
      }
    },
  },
};
