export const state = () => ({
  config: {},
});

export const mutations = {
  SAVE_OPTIONS (state, config) {
    state.config = config;
  },
};

export const actions = {
  async nuxtServerInit ({ commit, dispatch }, context) {
    await dispatch(`auth/init`);

    // Get constant data from the API and store it e.g. site settings, menu

    await dispatch(`wp/getMenu`);

    try {
      const { data } = await context.$axios.get(`${process.env.baseUrl}/wp-json/sotic-wp-api/options?id=site-settings`);
      commit(`SAVE_OPTIONS`, data.data);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  },
};
