/* eslint-disable quote-props */
export default {
  data () {
    return {
      fixtureWithMatchEvents: null,
      oppositeTeamEvents: [`Penalty faced`, `Own goal`],
      shotSupplementaryInfo: {},
      feedItemInformation: {
        foul: {
          icon: `icon-whistle`,
          events: [
            {
              key: `foul`,
              title: `Foul`,
              supplementaryInformation: {
                "Primary Player": {
                  path: `lineup.player._name`,
                  value: null,
                },
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Penalty`,
                },
              },
            },
          ],
        },
        correction: {
          icon: `icon-check`,
          events: [],
        },
        substitution: {
          icon: `icon-chevron-up1`,
          events: [],
        },
        time: {
          icon: `icon-stopwatch`,
          events: [
            {
              key: `start`,
              supplementaryInformation: {
                displayName: {
                  path: `state.displayName`,
                },
                statusName: {
                  path: `state.statusName`,
                },
                sourceName: {
                  path: `state.sourceName`,
                },
              },
            },
            {
              key: `end`,
              supplementaryInformation: {
                displayName: {
                  path: `state.displayName`,
                },
                statusName: {
                  path: `state.statusName`,
                },
                sourceName: {
                  path: `state.sourceName`,
                },
              },
            },
            { key: `start delay`, title: `Start Delay` },
            { key: `end delay`, title: `End Delay` },
          ],
        },
        goal: {
          icon: `icon-futbol-o`,
          events: [
            {
              key: `goal`,
              title: `Goal`,
              supplementaryInformation: {
                "Primary Player": { path: `lineup.player._name` },
                "Primary Known As": { path: `lineup.player.knownAs` },
                "Own Goal": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Own goal`,
                },
                "Secondary Player": {
                  path: `matchEventQualifiers.relatedMatchEvent.lineup.player._name`,
                  value: null, // NOTE: in the instance where we are finding an exclusive value that is unknown ahead of time, specify 'null'
                },
                Assist: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Assisted`,
                },
                "Assist Type": {
                  path: `matchEventQualifiers.relatedMatchEvent.matchEventType.name`,
                  value: null,
                },
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Penalty`,
                },
                "Left Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Left Footed`,
                },
                "Right Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Right Footed`,
                },
                Header: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Head`,
                },
                Body: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Other Body Part`,
                },
                GoalY: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth y co-ordinate`,
                },
                GoalZ: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth z co-ordinate`,
                },
              },
            },
          ],
        },
        card: {
          icon: ``, // NOTE: card icon will be dynamically populated by getCardEventIcon()
          events: [
            {
              key: `card`,
              title: `Card`,
              supplementaryInformation: {
                "Primary Player": { path: `lineup.player._name` },
                "Primary Known As": { path: `lineup.player.knownAs` },
                "Yellow Card": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Yellow Card`,
                },
                "Second Yellow": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Second yellow`,
                },
                "Red Card": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Red card`,
                },
              },
            },
          ],
        },
        other: {
          icon: ``,
          events: [
            {
              key: `penalty faced`,
              title: `Penalty!`,
            },
            {
              key: `attempt saved`,
              title: `Shot saved`,
              supplementaryInformation: {
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Penalty`,
                },
                "Left Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Left Footed`,
                },
                "Right Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Right Footed`,
                },
                Header: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Head`,
                },
                Body: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Other Body Part`,
                },
                GoalY: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth y co-ordinate`,
                },
                GoalZ: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth z co-ordinate`,
                },
                "Primary Player": {
                  path: `matchEventQualifiers.relatedMatchEvent.lineup.player._name`,
                  value: null,
                },
              },
            },
            {
              key: `miss`,
              title: `Shot missed`,
              supplementaryInformation: {
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Penalty`,
                },
                "Left Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Left Footed`,
                },
                "Right Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Right Footed`,
                },
                Header: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Head`,
                },
                Body: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Other Body Part`,
                },
                GoalY: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth y co-ordinate`,
                },
                GoalZ: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth z co-ordinate`,
                },
                "Primary Player": {
                  path: `matchEventQualifiers.relatedMatchEvent.lineup.player._name`,
                  value: null,
                },
              },
            },
            {
              key: `post`,
              title: `Woodwork`,
              supplementaryInformation: {
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Penalty`,
                },
                "Left Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Left Footed`,
                },
                "Right Footed": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Right Footed`,
                },
                Header: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Head`,
                },
                Body: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Other Body Part`,
                },
                GoalY: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth y co-ordinate`,
                },
                GoalZ: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Goal mouth z co-ordinate`,
                },
                "Primary Player": {
                  path: `lineup.player._name`,
                  value: null,
                },
              },
            },
            {
              key: `contentious referee decision`,
              title: `VAR`,
              supplementaryInformation: {
                Goal: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Goal Awarded`,
                },
                "No Goal": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Goal Not Awarded`,
                },
                Penalty: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Penalty Awarded`,
                },
                "No Penalty": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Penalty not awarded`,
                },
                "Red Card": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Red Card Given`,
                },
                "Card Upgrade": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Card upgrade`,
                },
                "Mistaken Identity": {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Mistaken Identity`,
                },
                Other: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `VAR - Other`,
                },
                Confirmed: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Referee Decision Confirmed`,
                },
                Overturned: {
                  path: `matchEventQualifiers.qualifierType.name`,
                  value: `Referee Decision Cancelled`,
                },
              },
            },
            { key: `corner awarded`, title: `Corner` },
          ],
        },
      },
    };
  },
  computed: {
    matchEvents () {
      return (
        (this.liveFeed?.match_events?.matcheventtype ?? null) || // NOTE: liveFeed is a prop passed down all the way from NextMatch, which represents the matchEventTypes selected from home_config
        (this.additionalConfig?.match_centre_config?.live_feed_events
          ?.match_events?.matcheventtype ??
          null)
      );
    },
    timeEvents () {
      return (
        this.feedItemInformation?.time?.events?.map(event => event.key) ||
        false
      );
    },
    eventTypeInformation () {
      const eventTypeInformation = [];
      for (const item of this.feed) {
        eventTypeInformation.push(
          this.getEventTypeInformation({
            event: item,
            feedItemInformation: this.feedItemInformation,
            timeEvents: this.timeEvents,
          }),
        );
      }
      return eventTypeInformation;
    },
    homeTeam () {
      if (this.fixtureWithMatchEvents) {
        return this.getTeam(this.fixtureWithMatchEvents, `Home`);
      }
      return false;
    },
    awayTeam () {
      if (this.fixtureWithMatchEvents) {
        return this.getTeam(this.fixtureWithMatchEvents, `Away`);
      }
      return false;
    },
    feed () {
      if (this.fixtureWithMatchEvents) {
        return this.generateNewFeedArray(this.fixtureWithMatchEvents) ?? null;
      }
      return null;
    },
    outputFeedInformation () {
      const eventTypeInformation = [];
      if (this.outputFeed) {
        for (const item of this.outputFeed) {
          if (item.matchEventType) {
            eventTypeInformation.push(
              this.getEventTypeInformation({
                event: item,
                feedItemInformation: this.feedItemInformation,
                timeEvents: this.timeEvents,
              }),
            );
          } else {
            eventTypeInformation.push(null);
          }
        }
      }
      return eventTypeInformation;
    },
    fixtureState () {
      return this.fixtureWithMatchEvents?.state?.displayName
        ? this.getFixtureState(this.fixtureWithMatchEvents)
        : null;
    },
    scoreLine () {
      if (
        this.fixtureWithMatchEvents?.fixtureTeams[0]?.score &&
        this.fixtureWithMatchEvents?.fixtureTeams[1]?.score
      ) {
        return this.getFixtureScore(this.fixtureWithMatchEvents) ?? null;
      }
      return null;
    },
  },
  methods: {
    // INTERFACES:
    generateNewFixtureWithMatchEventsObject (fixture, matchEvents) {
      return this.asyncResponseHandler(
        async () => await this.getFixtureWithMatchEvents(fixture, matchEvents),
      );
    },
    generateNewFeedArray (fixtureWithMatchEvents) {
      const feed = this.filterEvents({
        fixture: fixtureWithMatchEvents,
        feed: this.sortLiveFeed(
          this.prepareFeed(fixtureWithMatchEvents),
          `desc`,
        ),
        timeEvents: this.timeEvents,
      });
      if (!feed) return null;
      return feed;
    },
    getEventTypeInformation ({
      event,
      feedItemInformation = this.feedItemInformation,
      timeEvents,
    }) {
      const feedItemInformationDeepCopy =
        this.objectDeepCopy(feedItemInformation);
      const matchEventDeepCopy = this.objectDeepCopy(event);
      const matchEventType =
        matchEventDeepCopy.matchEventType.name.toLowerCase();
      const matchEventCategory = timeEvents.includes(matchEventType)
        ? `time`
        : this.getCorrespondingFeedInformationItemForMatchEventType({
          // REVISIT: see if there's a better way to handle this nested ternary
          matchEventCategory: matchEventType,
          matchEventType,
          feedItemInformation: feedItemInformationDeepCopy,
        })
          ? matchEventType
          : `other`;

      const allocatedFeedItemInformationForMatchEventType = {
        icon:
          this.getCardEventIcon(event) ||
          feedItemInformationDeepCopy[matchEventCategory]?.icon ||
          ``,
        title:
          this.getCorrespondingFeedInformationItemForMatchEventType({
            matchEventCategory,
            matchEventType,
            feedItemInformation: feedItemInformationDeepCopy,
          })?.title ||
          (timeEvents.includes(matchEventType)
            ? matchEventDeepCopy?.state.displayName
            : matchEventDeepCopy?.title),
        supplementaryInformation: {
          ...(this.parseSupplementaryInformationRegardingMatchEvent({
            matchEvent: matchEventDeepCopy,
            matchEventType,
            matchEventCategory,
          }) || ``),
        },
      };

      return allocatedFeedItemInformationForMatchEventType;
    },
    parseSupplementaryInformationRegardingMatchEvent ({
      matchEvent,
      matchEventType,
      matchEventCategory,
    }) {
      const matchEventDeepCopy = this.objectDeepCopy(matchEvent);
      const supplementaryInformation =
        this.getCorrespondingFeedInformationItemForMatchEventType({
          matchEventCategory,
          matchEventType,
        })?.supplementaryInformation || ``;

      if (!supplementaryInformation) return;

      const supplementaryInformationResults = {};

      const parseSupplementaryInformationItemRecursively = (
        key,
        supplementaryInfo,
      ) => {
        if (!supplementaryInfo) return;
        const resultOfCurrentObject =
          this.getSupplementaryInformationFromMatchEvent({
            matchEvent: matchEventDeepCopy,
            supplementaryInfo,
            exclusiveValue: supplementaryInfo.value === null ?? false,
          });

        const relatedInfo =
          Object.entries(supplementaryInfo).find(
            entry => typeof entry[1] === `object` && entry[1], // right-hand ampersands statement needed because JS will eval typeof `null` to be `object`
          ) || false;

        if (!relatedInfo) {
          return {
            [key]: resultOfCurrentObject,
          };
        }

        return {
          [key]: resultOfCurrentObject,
          [relatedInfo[0]]: parseSupplementaryInformationItemRecursively(
            relatedInfo[0],
            relatedInfo[1],
          ),
        };
      };

      for (const key of Object.keys(supplementaryInformation)) {
        Object.assign(
          supplementaryInformationResults,
          parseSupplementaryInformationItemRecursively(
            key,
            supplementaryInformation[key],
          ),
        );
      }

      return supplementaryInformationResults;
    },
    // ABSTRACTION BARRIER: // NOTE: methods below this point should be calculations as far as possible, and not actions
    async getFixtureWithMatchEvents (fixture, selectedMatchEvents) {
      const fixtureDeepCopy = fixture ? { ...fixture } : null; // only need to deep copy first-level items here
      const fixtureId = fixtureDeepCopy?.id ?? null;
      if (!fixtureId) return null;

      const matchEventIds = selectedMatchEvents.map(event => event.id);

      const params = {
        include: [
          `stage.season.competition`,
          `venue.venueNames`,
          `state`,
          `fixtureTeams.team.images`,
          `fixtureTeams.lineups.player`,
          `fixtureTeams.lineups.playerStats.playerStatType`,
          `matchEvents.matchEventType`,
          `matchEvents.state`,
          `matchEvents.lineup.player`,
          `matchEvents.matchEventQualifiers.qualifierType`,
          `matchEvents.matchEventQualifiers.relatedMatchEvent.lineup.player`,
          `matchEvents.matchEventQualifiers.relatedMatchEvent.matchEventType`,
          `fixtureTeams.substitutions`,
          `fixtureBroadcasts.broadcaster.images`,
          `fixtureBroadcasts.fixture`,
        ].join(`,`),
        filterInclude: {
          "matchEvents.matchEventTypeId": matchEventIds.join(`||`),
        },
      };
      const activeFixture = await this.$getStats(
        `fixture/${fixtureId}`,
        // `fixture/006b90bc-4592-473a-b943-08c68d888f91`, // TEMP: hardcoded ID for testing
        // `fixture/6c64ed2a-8b4d-4bd6-a449-6158efcc85f3`,
        // `fixture/e5046fd4-9a96-4724-afd3-64850d3cfb7e`,
        params,
      ).catch(_ => null);

      if (!activeFixture) return null;

      return activeFixture.data;
    },
    prepareFeed (fixtureWithMatchEvents) {
      const fixtureWithMatchEventsDeepCopy = this.objectDeepCopy(
        fixtureWithMatchEvents,
      );
      const outputFeed = [];

      if (!fixtureWithMatchEventsDeepCopy.matchEvents) return null;

      for (const event of fixtureWithMatchEventsDeepCopy.matchEvents) {
        outputFeed.push(event);
      }

      return outputFeed.length > 0 ? outputFeed : null;
    },
    sortLiveFeed (feed, order = `desc`) {
      if (!feed) return null;
      if (order === `asc`) {
        const sorted = [...feed].sort((a, b) => a.seconds - b.seconds);
        return sorted.sort((a, b) => a.minutes - b.minutes);
      }
      const sorted = [...feed].sort((a, b) => b.seconds - a.seconds);
      return sorted.sort((a, b) => b.minutes - a.minutes);
    },
    filterEvents ({ fixture, feed, timeEvents }) {
      // If feed item has same matchEventType, minutes and seconds, filter out the one with a value of 0
      let filteredEvents = [];
      if (feed) {
        filteredEvents = [...feed].filter((item, index, self) => {
          const matchEventType = item.matchEventType;
          const matchEventQualifiers = item.matchEventQualifiers;
          const minutes = item.minutes;
          const seconds = item.seconds;

          // If event shows once, it is valid
          if (
            fixture?.matchEvents?.filter(
              event =>
                event.matchEventType.id === matchEventType.id &&
                event.minutes === minutes &&
                event.seconds === seconds,
            ).length === 1
          ) {
            return true;
          }

          if (timeEvents.includes(matchEventType.name.toLowerCase())) {
            if (
              matchEventQualifiers.find(
                qualifier => qualifier.qualifierType.name === `Game end`,
              ) &&
              item.minutes === 0 &&
              item.seconds === 0
            ) {
              return false;
            }
            return item.fixtureTeamId === this.homeTeam.id;
          }

          // If event shows twice, filter out the one with a value of 0
          return parseInt(item.value) === 1;
        });
      }
      return filteredEvents;
    },
    isEventOppositeTeam (event) {
      return (
        this.oppositeTeamEvents.includes(event?.matchEventType?.name) ||
        event?.matchEventQualifiers?.find(qualifier =>
          this.oppositeTeamEvents.includes(qualifier.qualifierType.name),
        )
      );
    },
    getEventTeam (fixture, event, type = null) {
      const team = this.isEventOppositeTeam(event)
        ? fixture?.fixtureTeams?.find(team => team.id !== event.fixtureTeamId)
        : fixture?.fixtureTeams?.find(
          team => team.id === event.fixtureTeamId,
        ) ?? null;

      if (!team) return null;

      if (this.timeEvents.includes(event?.matchEventType?.name.toLowerCase())) {
        return null;
      }

      return type === `name`
        ? team.team.teamNames[0].displayName
        : type === `team`
          ? team.team
          : {
            name: team.team.teamNames[0].displayName,
            id: team.team.id,
            team: team.team,
          };
    },
    getMatchTime (event) {
      const minutes = event.minutes;
      const seconds = event.seconds;
      const minutesOutput = seconds > 0 ? minutes + 1 : minutes;

      // If ET second half injury time
      if (
        minutes * 60 + seconds > 120 * 60 &&
        event.state.sourceName === `ExtraTimeSecondHalf`
      ) {
        return `120+${minutesOutput - 120}'`;
      }

      // If ET first half injury time
      if (
        minutes * 60 + seconds > 105 * 60 &&
        event.state.sourceName === `ExtraTimeFirstHalf`
      ) {
        return `105+${minutesOutput - 105}'`;
      }

      // If second half injury time
      if (
        minutes * 60 + seconds > 90 * 60 &&
        event.state.sourceName === `SecondHalf`
      ) {
        return `90+${minutesOutput - 90}'`;
      }

      // If first half injury time
      if (
        minutes * 60 + seconds > 45 * 60 &&
        event.state.sourceName === `FirstHalf`
      ) {
        return `45+${minutesOutput - 45}'`;
      }

      return `${minutesOutput}'`;
    },
    getQualifers (item) {
      return item.matchEventQualifiers
        .map(qualifier => qualifier.qualifierType.name)
        .join(`,`);
    },
    getEventDisplaySide (event) {
      return event.fixtureTeamId
        ? event.fixtureTeamId === this.homeTeam.id
          ? `alignself-fs`
          : `alignself-fe flex-row-rev`
        : `alignself-fs`;
    },
    getCorrespondingFeedInformationItemForMatchEventType ({
      matchEventCategory,
      matchEventType,
      feedItemInformation = this.feedItemInformation,
    }) {
      return (
        feedItemInformation[matchEventCategory]?.events.find(
          title => title.key === matchEventType,
        ) || false
      );
    },
    getCardEventIcon (event) {
      const cardMatchEvent =
        event.matchEventQualifiers.find(
          qualifier =>
            qualifier.qualifierType.name === `Yellow Card` ||
            qualifier.qualifierType.name === `Second yellow` ||
            qualifier.qualifierType.name === `Red card`,
        ) || null;
      if (!cardMatchEvent) return ``;
      return cardMatchEvent.qualifierType.name
        .split(` `)
        .join(`-`)
        .toLowerCase();
    },
    getSupplementaryInformationFromMatchEvent ({
      matchEvent,
      supplementaryInfo,
      exclusiveValue = false,
    }) {
      const matchEventDeepCopy = this.objectDeepCopy(matchEvent); // NOTE: we need to deep copy here because we're ultimately trying to retrieve a nested object within matchEvent, which could be mutated at the place of invocation

      // NOTE: but we don't need to deep copy supplementaryInfo, because we're just using its properties as a reference -- it's safe from mutation
      if (!Object.keys(supplementaryInfo).includes(`value`)) {
        return this.objectDeepLookupByPath(
          matchEventDeepCopy,
          supplementaryInfo.path,
        );
      }

      const deepFindSupplementaryInformationWithExpectedValue = exclusiveValue // TODO: need to specify `expectedKey` once it's built into objectDeepLookupByPathAdvanced
        ? this.objectDeepLookupByPathAdvanced({ expectedValue: null })
        : this.objectDeepLookupByPathAdvanced({
          expectedValue: supplementaryInfo.value,
        });
      return deepFindSupplementaryInformationWithExpectedValue(
        matchEventDeepCopy,
        supplementaryInfo.path,
      );
    },
    getTimeLabel (event) {
      const labels = [
        { key: `FirstHalf`, label: `1st Half` },
        { key: `SecondHalf`, label: `2nd Half` },
        { key: `ExtraTimeFirstHalf`, label: `ET 1st Half` },
        { key: `ExtraTimeSecondHalf`, label: `ET 2nd Half` },
      ];
      const half = event.state.sourceName; // FirstHalf, SecondHalf or ExtraTimeHalfs
      const type = event.matchEventType.name; // Start or End

      if (
        event.matchEventQualifiers.find(
          qualifier => qualifier.qualifierType.name === `Game end`,
        )
      ) {
        return `Match Ended`;
      }

      return half === `FirstHalf` && type === `Start`
        ? `Kick Off`
        : half === `FirstHalf` && type === `End`
          ? `Half Time`
          : half === `SecondHalf` && type === `Start`
            ? `Kick Off Second Half`
            : half === `SecondHalf` && type === `End`
              ? `Full Time`
              : `${labels.find(label => label.key === half)?.label} ${type}`;
    },
    getEventLabel (index) {
      if (
        this.timeEvents.includes(
          this.outputFeed[index].matchEventType.name.toLowerCase(),
        )
      ) {
        return this.getTimeLabel(this.outputFeed[index]);
      }

      // Penalty workarounds
      if (this.outputFeed[index].matchEventType.name === `Foul`) {
        return this.outputFeed[index].matchEventQualifiers.find(
          qualifier => qualifier.qualifierType.name === `Penalty`,
        )
          ? `Penalty!`
          : `Foul`;
      }
      if (this.outputFeed[index].matchEventType.name === `Attempt Saved`) {
        return this.outputFeed[index].matchEventQualifiers.find(
          qualifier => qualifier.qualifierType.name === `Penalty`,
        )
          ? `Penalty Saved`
          : `Save`;
      }
      if (this.outputFeed[index].matchEventType.name === `Miss`) {
        return this.outputFeed[index].matchEventQualifiers.find(
          qualifier => qualifier.qualifierType.name === `Penalty`,
        )
          ? `Penalty Missed`
          : `Miss`;
      }

      return (
        this.outputFeedInformation[index]?.title ||
        this.outputFeed[index].matchEventType.name
      );
    },
    getEventIcon (index) {
      return this.outputFeedInformation[index].icon || null;
    },
    removeNonPenalty (feed) {
      const penaltyEventTypes = [`Attempt Saved`, `Miss`, `Foul`];
      // Remove any foul, miss or save events that don't have a Penalty qualifier
      const filteredFeed = feed.filter(event => {
        if (
          event.matchEventType &&
          penaltyEventTypes.includes(event.matchEventType.name)
        ) {
          return event.matchEventQualifiers.find(
            qualifier => qualifier.qualifierType.name === `Penalty`,
          );
        }
        return true;
      });

      return filteredFeed;
    },
  },
};
